import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {TabView} from "primereact/tabview";
import {TabPanel} from "primereact/tabview";
import ProjectListComponent from "../../components/project/ProjectListComponent";
import axios from "axios";
import ReceiptListComponent from "../../components/receipt/ReceiptListComponent";
import MetricsListComponent from "../../components/metrics/MetricsListComponent";
import TracklogListComponent from "../../components/tracklog/TracklogListComponent";
import EmployeeImportComponent from "../../components/employee/EmployeeImportComponent";
import {Sidebar} from "primereact/sidebar";
import TracklogImportComponent from "../../components/tracklog/TracklogImportComponent";

export const TracklogDashboardPage = (props) => {

  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Adatok' + ' - ' + process.env.REACT_APP_TITLE;
    if (document.getElementsByClassName('activeMenu')[0]) {
      document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
    }
    if (document.getElementsByClassName('track_log')[0]) {
      document.getElementsByClassName('track_log')[0].classList.add("activeMenu")
    }

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/metrics?" + JSON.stringify({
        first: 0,
        rows: 9999,
        page: 0,
      }),
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setMetrics(response.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })

  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_track_log') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {metrics.map((metric, index) => {
                  return (
                    <TabPanel header={metric.name}>
                      <div className={"p-p-5"}>
                          <TracklogListComponent metricsId={metric.id} />
                      </div>
                    </TabPanel>
                  )
                })}
              </TabView>
            </div>
          </div>
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
          <span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>
          Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
          {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
          <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
          }
        </p>
      )}
      <Sidebar position="left" visible={window.App.state.sidebarTracklogImport} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => {
                 window.App.setState({sidebarTracklogImport: false})
               }}>
        <h2 className={"text-primary"}>Adatok importálása</h2>
        <TracklogImportComponent method="post"/>
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(TracklogDashboardPage);
