import React, {useState, useEffect} from 'react';
import axios from "axios";
import {withTranslation} from 'react-i18next';
import moment from "moment";
import {BlockUI} from "primereact/blockui";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

const TracklogListComponent = (props) => {

  const [measures, setMeasures] = useState([]);
  const [tracklogs, setTracklogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderTracklogList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/track_log/" + props.metricsId + "/?" + JSON.stringify(lazyParams).replace('"multiSortMeta":[],', ''),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setTracklogs(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadLazyData();
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/metrics/" + props.metricsId,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setMeasures(response.data.measures);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [lazyParams, window.App.state.rerenderTracklogList === true])

  return (
    <>


      <DataTable
        emptyMessage="Nincs találat."
        value={tracklogs}
        paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} lazy
        paginatorTemplate={pager} rows={rows}
        loading={loading}
        emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
      >
        <Column field="projectName" header="Projekt" sortable></Column>
        <Column field="createdAt" header="Létrehozva" sortable></Column>
        {measures.map((measureItem, index) => {
          return (
            <Column field="createdAt" header={measureItem.name} sortable body={(data) => {
              let value = data.measures.filter(item => item.id === Number(index + 1))
              if (value[0] && value[0] != undefined) {
                return (
                  <>
                    {value[0].value}
                  </>
                )
              } else {
                return (
                  <>
                    &nbsp;
                  </>
                )
              }
            }}></Column>
          )
        })}
      </DataTable>
      {/*{loading === false ? (*/}
      {/*  <>*/}
      {/*    {tracklogs && tracklogs[0] ? (*/}
      {/*      <div*/}
      {/*        className={"p-datatable p-component p-datatable-selectable p-datatable-auto-layout p-datatable-responsive-scroll p-datatable-striped"}>*/}
      {/*        <table className={"p-datatable-table manual-table striped w-100"}>*/}
      {/*          <thead className="p-datatable-thead">*/}
      {/*          <tr>*/}
      {/*            <th>*/}
      {/*              Projekt*/}
      {/*            </th>*/}
      {/*            <th>*/}
      {/*              Létrehozva*/}
      {/*            </th>*/}
      {/*            {measures && measures[0] &&*/}
      {/*            <>*/}
      {/*              {measures.map((item, index) => {*/}
      {/*                return (*/}
      {/*                  <th>*/}
      {/*                    {item.name}*/}
      {/*                  </th>*/}
      {/*                )*/}
      {/*              })}*/}
      {/*            </>*/}
      {/*            }*/}
      {/*          </tr>*/}
      {/*          </thead>*/}
      {/*          <tbody className={"p-datatable-tbody"}>*/}
      {/*          {tracklogs.map((itemTracklog, index) => {*/}
      {/*            return (*/}
      {/*              <tr>*/}
      {/*                <td>*/}
      {/*                  {itemTracklog.projectName}*/}
      {/*                </td>*/}
      {/*                <td>*/}
      {/*                  {moment.utc(itemTracklog.createdAt).local().format('YYYY-MM-DD HH:mm')}*/}
      {/*                </td>*/}
      {/*                {measures.map((measureItem, index) => {*/}
      {/*                  let value = itemTracklog.measures.filter(item => item.id === Number(index + 1))*/}
      {/*                  if (value[0] && value[0] != undefined) {*/}
      {/*                    return (*/}
      {/*                      <td>*/}
      {/*                        {value[0].value}*/}
      {/*                      </td>*/}
      {/*                    )*/}
      {/*                  } else {*/}
      {/*                    return (*/}
      {/*                      <td>*/}
      {/*                        &nbsp;*/}
      {/*                      </td>*/}
      {/*                    )*/}
      {/*                  }*/}
      {/*                })}*/}
      {/*              </tr>*/}
      {/*            )*/}
      {/*          })}*/}
      {/*          </tbody>*/}
      {/*        </table>*/}
      {/*      </div>*/}
      {/*    ) : (*/}
      {/*      <div*/}
      {/*        className={"p-datatable p-component p-datatable-selectable p-datatable-auto-layout p-datatable-responsive-scroll p-datatable-striped"}>*/}
      {/*        <table className={"p-datatable-table manual-table striped w-100"}>*/}
      {/*          <thead className="p-datatable-thead">*/}
      {/*          <tr>*/}
      {/*            <th>*/}
      {/*              &nbsp;*/}
      {/*            </th>*/}
      {/*          </tr>*/}
      {/*          </thead>*/}
      {/*          <tbody className={"p-datatable-tbody"}>*/}
      {/*          <tr>*/}
      {/*            <td>*/}
      {/*              Nincs adat.*/}
      {/*            </td>*/}
      {/*          </tr>*/}
      {/*          </tbody>*/}
      {/*        </table>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  <BlockUI blocked={loading}>*/}
      {/*    <div className={"p-p-5"}>*/}
      {/*      <span className={"p-d-block p-py-4 p-text-center"}>*/}
      {/*      <i className="pi pi-spin pi-spinner" style={{fontSize: '1.5em'}}></i>*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*  </BlockUI>*/}
      {/*)}*/}
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Adatok importálása" icon="pi pi-plus" className="p-button-success"
                  onClick={() => {
                    window.App.setState({
                      sidebarTracklogImport: true,
                      propsTracklog: {id: props.metricsId}
                    })
                  }}
          />
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(TracklogListComponent);
